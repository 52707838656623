import React, { Component } from "react";
import { Quiz } from "components/Quiz";
import { Skeleton } from "antd";
import QuizHeader from "../HomeTraning/HomeHeader/QuizHeader";
import update from "immutability-helper";
import Axios from "../../utils/request";
import HometrainingApis from "../../utils/hometrainingApis";

class QuizList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      quizListData: null,
      solvedQuizData: null,
      currentQuiz: null,
      currentIndex: 0,
      completed: false,
      step: false,
      status: [],
    };
  }

  componentDidMount() {
    const { quizList, userId, isHomework } = this.props;
    console.log("quizList: ", quizList);
    const _status = [];
    if (quizList) {
      let _currentIndex = 0;
      let initCurrentIndex = false;
      if (isHomework) {
        quizList.map((quiz, index) => {
          if (!quiz.solvedMap[userId] && !initCurrentIndex) {
            _currentIndex = index;
            initCurrentIndex = true;
          }
          if (quiz.solvedMap && quiz.solvedMap[userId]) {
            if (quiz.solvedMap[userId].retry) {
              _status.push(quiz.solvedMap[userId].retry.correct);
            } else {
              _status.push(quiz.solvedMap[userId].correct);
            }
          } else {
            _status.push(null);
          }
        });
      }
      this.setState(
        {
          quizListData: quizList,
          status: _status,
          currentIndex: _currentIndex,
          solvedQuizData: [],
          step: false,
          // completed: _curIndex === quizList.length ? true : false
        },
        () => {
          this.load(quizList[_currentIndex].quizId);
        }
      );
    }
  }
  handleAppendStatus = (correct) => {
    this.setState({
      status: update(this.state.status, {
        $push: [correct],
      }),
    });
  };
  load = (quizId, data = null) => {
    this.setState({
      isLoading: true,
    });
    console.log("solveddata: ", data);
    // Axios.get(`/quiz/v2/quizzes/${quizId}`).then(response => {
    HometrainingApis.loadQuiz(quizId, data).then((response) => {
      this.setState({
        isLoading: false,
        currentQuiz: response.data,
      });
    });
  };

  solve = (quizId, answers, vars) => {
    return HometrainingApis.solveQuiz(
      this.props.classroomId,
      this.props.lectureId,
      this.props.quetureId,
      quizId,
      answers,
      vars
    );
    // if (this.props.isHomework) {
    // } else {
    //   return Axios.post(`/quiz/v2/quizzes/${quizId}/solve`, {
    //     quizId: quizId,
    //     answers: answers,
    //     vars: vars
    //   });
    // }
  };

  handleFinish = () => {
    this.setState({
      completed: true,
    });
  };

  handleStep(index, data = null) {
    const { quizListData, solvedQuizData } = this.state;
    console.log(index);
    console.log(data);
    if (data) {
      this.setState({
        step: true,
        currentIndex: index,
        solvedQuizData: update(this.state.solvedQuizData, {
          $push: [data],
        }),
        status: update(this.state.status, {
          $push: [data.correct],
        }),
      });
      this.load(quizListData[index].quizId, data);
    } else {
      if (index === solvedQuizData.length) {
        console.log("bakc to current ");
        this.setState({
          step: false,
          currentIndex: index,
        });
        this.load(quizListData[index].quizId);
        return;
      }
      if (index === quizListData.length) {
        return;
      }

      this.setState({
        currentIndex: index,
        step: true,
      });
      this.load(quizListData[index].quizId, solvedQuizData[index]);
    }
  }

  handleAppendResult = (res, isLast) => {
    const { currentIndex, quizListData } = this.state;
    const _nextIndex = isLast ? currentIndex : currentIndex + 1;

    if (isLast) {
      console.log("isLast");
    }

    console.log("curidnex: ", currentIndex);
    this.setState({
      step: isLast,
      currentIndex: _nextIndex,
      solvedQuizData: update(this.state.solvedQuizData, {
        [currentIndex]: {
          $set: res,
        },
        // $push: [res]
      }),
      status: update(this.state.status, {
        [currentIndex]: {
          $set: res.correct,
        },
        // $push: [res.correct]
      }),
    });
    if (isLast) {
      this.load(quizListData[_nextIndex].quizId, res);
    } else {
      this.load(quizListData[_nextIndex].quizId);
    }
  };

  render() {
    const { onBack, title, isHomework, quizList } = this.props;
    const {
      isLoading,
      completed,
      currentIndex,
      currentQuiz,
      quizListData,
      solvedQuizData,
      step,
      status,
    } = this.state;
    console.log("step: ", step);
    console.log("status: ", status);
    console.log("currentIndex: ", currentIndex);
    console.log("currentQuiz: ", currentQuiz);
    console.log("quizListData: ", quizListData);
    console.log("solvedQuizData: ", solvedQuizData);
    let onFinish = null;
    if (quizListData) {
      const checkLength = quizListData.length - 1;
      // if (!step && status.length === checkLength) {
      if (!step && currentIndex === checkLength) {
        onFinish = this.handleFinish;
      }
    }
    if (completed) {
      return <div>completed</div>;
    }

    console.log("onFinish: ", onFinish);

    return (
      <Skeleton loading={isLoading} active>
        <QuizHeader
          onBack={() => {
            if (solvedQuizData.length === quizList.length) {
              onBack(true);
            } else {
              onBack();
            }
          }}
          title={title}
        />
        {currentQuiz && (
          <Quiz
            data={currentQuiz}
            solver={this.solve}
            expectedCount={quizListData.length}
            status={status}
            onAppendResult={(result, isLast) => {
              this.handleAppendResult(result, isLast);
            }}
            // onNext={() => this.handleStep(currentIndex + 1)}
            // onPrev={() => this.handleStep(currentIndex - 1)}
            onStep={(index, data) => this.handleStep(index, data)}
            onFinish={onFinish}
            currentIndex={currentIndex}
            stepQuizData={step ? solvedQuizData[currentIndex] : null}
            solvedQuizData={solvedQuizData}
            step={step}
            quizStep={currentIndex < status.length ? currentIndex : null}
            onSolvedData={this.handleSolvedData}
            quizType={"hometraning"}
            isHomework={isHomework}
          />
        )}
      </Skeleton>
    );
  }
}

export default QuizList;
