import React, { Component } from "react";
import classNames from "classnames";
import Popup from "../../../components/Popup/Popup";
import {
  orderBy,
  values,
  mapValues,
  remove,
  filter,
  map,
  find,
  sortedUniq,
} from "lodash";
import { Tooltip, Icon } from "antd";
import { ConstantsContext } from "utils/ConstantsContext";
import { button } from "components/Game/Penguin/spaceBubble/objects/objects";

export default class StudentListPopup extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      mvps: [],
      studentList: [],
      curSeq: null,
      quetureSectionList: [],
      studentQuizInfo: null,
    };
  }

  componentDidMount() {
    const { lectureData } = this.props;
    if (lectureData) {
      const { seq, attendances, students, sections, lectureId } = lectureData;
      console.log("lectureData: ", lectureData);

      const quizSections = filter(lectureData.sections, function (o) {
        return o.type === "QUIZ";
      });

      console.log("quizList", quizSections);
      console.log(
        "quizList",
        quizSections.map((s) => s.quetureId)
      );
      let _studentInfoList = {};

      students.map((student) => {
        _studentInfoList[student.studentId] = {};
      });

      let _quizCorrectCountList = [];

      quizSections &&
        quizSections.map((quizInfo) => {
          const quizList = quizInfo.quiz;
          const quetureId = quizInfo.quetureId;
          _quizCorrectCountList[quizInfo.quetureId] = {};
          quizList.map((quiz) => {
            if (
              Object.keys(quiz.solvedMap) &&
              Object.keys(quiz.solvedMap).length > 0
            ) {
              Object.keys(quiz.solvedMap).map((studentId) => {
                if (_studentInfoList[studentId]) {
                  if (!_studentInfoList[studentId][quetureId]) {
                    _studentInfoList[studentId][quetureId] = {};
                  }
                  if (!_studentInfoList[studentId][quetureId]["count"]) {
                    _studentInfoList[studentId][quetureId] = {
                      count: 0,
                    };
                  }
                  if (quiz.solvedMap[studentId].correct === true) {
                    _studentInfoList[studentId][quetureId]["count"]++;
                  }
                }
              });
            }
          });
        });

      console.log("_studentInfoList: ", _studentInfoList);

      this.setState({
        mvps:
          lectureData.mvps.length > 0 ? map(lectureData.mvps, "studentId") : [],
        studentList: lectureData.students || [],
        curSeq: lectureData.seq,
        quetureSectionList: quizSections,
        studentQuizInfo: _studentInfoList,
      });
    }
  }

  handleChangeMvp(e) {
    console.log(e.target.checked);
    console.log(e.target.value);
    const { mvps } = this.state;
    let _mvps = mvps;
    if (e.target.checked) {
      _mvps = [e.target.value, ..._mvps];
    } else {
      _mvps = remove(_mvps, function (n) {
        return n !== e.target.value;
      });
    }
    this.setState({
      mvps: _mvps,
    });
  }

  render() {
    const { isOpen, onClose, lectureData, onAttendance } = this.props;
    const { lectureId, attendances } = lectureData;
    const { studentList, quetureSectionList, studentQuizInfo } = this.state;
    const { mvps } = this.state;
    console.log("mvps: ", mvps);
    console.log("studentList: ", studentList);
    console.log("quetureSectionList: ", quetureSectionList);
    console.log("studentQuizInfo: ", studentQuizInfo);
    console.log("lectureData: ", lectureData);
    const isKids = lectureData?.lectureType === "kids";
    return (
      <Popup isOpen={isOpen} className="popup-student-list">
        <div className="popup-closebtn" onClick={() => onClose(null)}></div>
        <div className="title">학생 리스트</div>
        <div className="list">
          {/* */}
          <table className="list__title">
            <tbody>
              <tr>
                <th className="name">이름</th>
                <th className="attendance">출석</th>
                {quetureSectionList.map((quiz, index) => (
                  <th key={index}>{`깨처 ${index + 1} 정답률`}</th>
                ))}
                <th className="mvp">
                  {isKids ? "칭찬 선정 횟수" : "MVP 선정 횟수"}
                </th>
                <th className="mvp">{isKids ? "칭찬해주세요" : "MVP"}</th>
              </tr>
            </tbody>
          </table>
          {/* 
          <ul className="list__title">
            <li>이름</li>
            <li>출석</li>
            <li>홈트레이닝</li>
            <li>미션</li>
            {quetureSectionList.map((quiz, index) => (
              <li key={index}>{`깨처 ${index + 1} 정답률`}</li>
            ))}
            <li>MVP</li>
          </ul>
          */}
          <div className="list__content">
            {studentList &&
              studentList.map((student, index) => (
                <table key={index} className="list__content-list">
                  <tbody>
                    <tr>
                      <td className="name">
                        {student.name}
                        {student.birthday && (
                          <span>
                            &nbsp;
                            {student.birthday.age + "세"}
                          </span>
                        )}
                        {this.context.type === "campus" && (
                          <Tooltip
                            title={
                              <span>
                                {student.account}&nbsp;/&nbsp;
                                {student.birthday &&
                                  "" +
                                    student.birthday.year +
                                    (student.birthday.month > 9
                                      ? student.birthday.month
                                      : "0" + student.birthday.month) +
                                    (student.birthday.day > 9
                                      ? student.birthday.day
                                      : "0" + student.birthday.day)}
                              </span>
                            }
                          >
                            <Icon
                              type="info-circle"
                              // theme="twoTone"
                              style={{ marginLeft: 16 }}
                              color="#333a53"
                            />
                          </Tooltip>
                        )}
                      </td>
                      {/*
                li.attaendnce 와 li.mission 에
                기본이 x 표시. 했을시 (O표) is-yes

                li.home 에
                깨처를 한개도 안봤을시 : is-not

             */}
                      <td
                        className={classNames("attendance", {
                          "is-kids": isKids,
                          "is-yes":
                            attendances &&
                            attendances[student.studentId] &&
                            attendances[student.studentId].status === "ok",
                        })}
                        // onClick={() => onAttendance(student.studentId, true)}
                      >
                        <span></span>
                        {isKids &&
                          !(
                            attendances &&
                            attendances[student.studentId] &&
                            attendances[student.studentId].status === "ok"
                          ) && (
                            <button
                              onClick={() => onAttendance(student.studentId)}
                              style={{ fontSize: 16 }}
                            >
                              출석하기
                            </button>
                          )}
                      </td>
                      {quetureSectionList.map((quiz, index) => {
                        const solvedQueture =
                          studentQuizInfo &&
                          studentQuizInfo[student.studentId] &&
                          studentQuizInfo[student.studentId][quiz.quetureId];
                        return (
                          <td
                            key={index}
                            className={classNames("home", {
                              "is-not": !solvedQueture,
                            })}
                          >
                            <span>
                              {solvedQueture ? solvedQueture["count"] : ""}
                            </span>
                          </td>
                        );
                      })}
                      {/* <li className="home">
                    <span></span>
                  </li>
                  <li className="mission">
                    <span></span>
                  </li> */}
                      <td className="mvp">{student.mvpCount || 0}</td>
                      <td className="mvp">
                        <div className="inputwrap">
                          <input
                            type="checkbox"
                            id={`mvp-${student.studentId}`}
                            onChange={(e) => this.handleChangeMvp(e)}
                            value={student.studentId}
                            checked={mvps.includes(student.studentId)}
                            name="mvps"
                          />
                          <span></span>
                          <label htmlFor={`mvp-${student.studentId}`} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
          </div>
        </div>
        <div className="chk-btn">
          <button onClick={() => onClose(this.state.mvps)}>확인</button>
        </div>
      </Popup>
    );
  }
}
