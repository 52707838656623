import { filter } from "lodash";
import { getHost } from "./APIUtils";
import Axios from "./request";
import { isIOS } from "react-device-detect";

export const isImage = (file) => file["type"].includes("image");
export const isCsv = (file) => file["type"].includes("csv");

export const parseClassName = (courseName) => {
  return courseName.match(/\b[a-zA-Z0-9]/g).join("");
};

export const diffDays = (end, start = new Date()) => {
  const oneDay = 1000 * 60 * 60 * 24;
  return Math.round((end - start) / oneDay);
};

export const formateDate = (
  dateTime,
  type = "-",
  hasDay = false,
  hasYear = true
) => {
  let month = "" + (dateTime.getMonth() + 1);
  let year = "" + dateTime.getFullYear();
  let day = "" + dateTime.getDate();
  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  if (hasDay && hasYear) {
    return [year, month, day].join(type);
  } else if (hasDay && !hasYear) {
    return [month, day].join(type);
  } else if (!hasDay && hasYear) {
    return [year, month].join(type);
  } else {
    return [year, month].join(type);
  }
};

export const formateFullKoreanDate = (str) => {
  if (!str) {
    return;
  }
  const dateArr = str.split(" ");
  const formattedDate = getKoreanDate(dateArr[0]);
  const formattedTime = formateKoreanTime(dateArr[1]);

  return formattedDate + " " + formattedTime;
};

export const getWeekdayTable = () => {
  return [
    { label: "월요일", value: "mon" },
    { label: "화요일", value: "tue" },
    { label: "수요일", value: "wed" },
    { label: "목요일", value: "thu" },
    { label: "금요일", value: "fri" },
    { label: "토요일", value: "sat" },
    { label: "일요일", value: "sun" },
  ];
};

export const formateKoreanTime = (str, isDateType = false) => {
  if (!str) {
    return;
  }
  let _str = str;
  if (isDateType) {
    const _date = new Date(str);
    _str = _date.getHours() + ":" + _date.getMinutes();
  }
  const _timeArr = _str.split(":");
  const _hour = _timeArr[0];
  const _min = _timeArr[1];

  let time = "";

  if (Number.parseInt(_hour) > 11) {
    if (_hour == 12) time = "오후 " + 12 + "시";
    else time = "오후 " + (_hour - 12) + "시";
  } else {
    if (_hour == 0) time = "오전 " + 12 + "시";
    else time = "오전 " + _hour + "시";
  }
  if (_min > 0) {
    time += " " + (_min - 0) + "분";
  }

  return time;
};

export const getKoreanToday = () => {
  const today = new Date();
  const week = today.getDay();
  const weekName = getWeekdayTable()[week - 1].label;
  return `${today.getMonth() + 1}월 ${today.getDate()}일 ${weekName}`;
};

export const getKoreanDate = (str, isTimestamp = false) => {
  if (!str) {
    return;
  }

  const date = new Date(str);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  if (str.length >= 10 || isTimestamp) {
    return `${year}년 ${month}월 ${day}일`;
  } else {
    return `${year}년 ${month}월`;
  }
};

export const getWeekdayName = (day) => {
  if (!day) {
    return;
  }
  const weekdays = getWeekdayTable();
  return filter(weekdays, { value: day })[0].label;
};

export const isEmail = (email) => {
  const regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i; //이메일 정규식

  return regExp.test(email);
};

export const isIPadSafari = () => {
  if (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1) {
    return true;
  }

  const userAgent = window.navigator.userAgent;

  const ver = iOSversion();
  const iPadSafari =
    !!userAgent.match(/iPad/i) && // Detect iPad first.
    ver[0] >= 12 &&
    !!userAgent.match(/WebKit/i) && // Filter browsers with webkit engine only
    !userAgent.match(/CriOS/i) && // Eliminate Chrome & Brave
    !userAgent.match(/OPiOS/i) && // Rule out Opera
    !userAgent.match(/FxiOS/i) && // Rule out Firefox
    !userAgent.match(/FocusiOS/i);

  console.log(userAgent, window.navigator.maxTouchPoints);
  console.log("iOS version", ver);
  console.log("isIPad", iPadSafari);
  return iPadSafari;
};

function iOSversion() {
  if (/iP(hone|od|ad)/.test(window.navigator.platform)) {
    var v = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
  return 0;
}

export const formateTime = (dateTime, formate = ":") => {
  const hours = dateTime.getHours();
  const minutes =
    dateTime.getMinutes() < 10
      ? "0" + dateTime.getMinutes()
      : dateTime.getMinutes();
  const seconds =
    dateTime.getSeconds() < 10
      ? "0" + dateTime.getSeconds()
      : dateTime.getSeconds();

  return `${hours}${formate}${minutes}${formate}${seconds}`;
};

export const copyToClipboard = (id) => {
  const textArea = document.createElement("textarea");

  textArea.style.height = "0px";
  textArea.style.width = "0px";
  textArea.style.opacity = "0";
  textArea.value = id;
  document.body.appendChild(textArea);
  let range, selection;
  if (isIOS) {
    range = document.createRange();
    range.selectNodeContents(textArea);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.select();
  }

  document.execCommand("copy");
  document.body.removeChild(textArea);
};

export const getSectionName = (type, index) => {
  const sectionNameMap = {
    OPENED: `출석확인`,
    QUETURE: `${index}번째 깨처`,
    SUMMARY: `개념정리`,
    GROUP: `팀설정`,
    QUIZ: `문제`,
    GAME: `게임`,
    ACTIVITY: `활동`,
    MVP: `MVP`,
    CLOSED: `수업종료 문자발송`,
    MAIL: `수업종료 메일발송`,
    CHALLENGE: `도전문제`,
    ENDQUIZ: `정리퀴즈`,
    STORYTELLING: `스토리텔링`,
    PICTUREQUIZ: `그림퀴즈`,
    QUEBONSONG: "깨봉송",
  };
  return sectionNameMap[type];
};

export const numberToText = (num) => {
  if (!num) {
    return;
  }

  if (num > 10) {
    return num;
  }

  const texts = [
    "0",
    "첫",
    "두",
    "세",
    "네",
    "다섯",
    "여섯",
    "일곱",
    "여덟",
    "아홉",
    "열",
  ];

  return texts[num];
};

export const getProfileUrl = (contentId) => {
  if (!contentId) {
    return null;
  }

  // Axios.get(`/blob/v1/contents/${contentId}/blob`).then(res => {
  //   console.log(res);
  // });

  const url = `${getHost()}/blob/v1/contents/${contentId}/blob`;

  return url;
};

export const formateSectionState = (state) => {
  if (!state) return "";
  const stateMap = {
    forFree: "자유용",
    forReference: "참고용",
  };
  return stateMap[state];
};

export const formateQuetureName = (str) => {
  if (!str) {
    return null;
  }

  let result = str;

  const regExp = /\(([^)]+)\)/;
  const matches = regExp.exec(str);
  console.log("matches: ", matches);
  result.replace(regExp, "$1");
  if (result.match(regExp)) {
    result = result.replace(regExp, "$1");
  }

  const regExp2 = /\[.*?\]/;
  if (result.match(regExp2)) {
    result = result.replace(regExp2, "").trim();
  }

  if (result.startsWith("J_")) {
    result = result.replace("J_", "");
  }

  return result.trim();
};

export const getSeqText = (lecture) => {
  return lecture.title && lecture.title.match(/[0-9]+(회차|강)/)
    ? lecture.title
    : `${lecture.seq}회차`;
};

export const getTextLength = (str) => {
  let len = 0;
  for (let i = 0; i < str.length; i++) {
    if (escape(str.charAt(i)).length === 6) {
      len++;
    }
    len++;
  }
  return len;
};
