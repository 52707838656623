import React, { useState, useEffect } from "react";
import { filter, values, mapValues } from "lodash";
import "./_queturelist.scss";
import CommonApis from "../../utils/commonApis";
import axios from "axios";
import { Skeleton, Row, Col } from "antd";
import { numberToText } from "../../utils/utils";
import classNames from "classnames";
import PopupQueture from "../PopupQueture/PopupQueture";
import { ConstantsContext } from "utils/ConstantsContext";

const ReviewQuetureList = (props) => {
  const { quetureIds, lectureId, onEnterSolveQuiz, onShowQueturePopup, handleFindSolveQuiz, searchTitle} = props;
  const [isLoading, setLoading] = useState(false);
  const [quetureList, setQuetureList] = useState([]);
  const context = React.useContext(ConstantsContext);

  useEffect(() => {
    if (quetureIds && quetureIds.length > 0 && quetureList.length === 0) {
      setLoading(true);
      CommonApis.getQuetureDataList(quetureIds).then(
        axios.spread((...responses) => {
          // console.log("queture responses: ", responses);
          const _quetureList = [];
          responses.map((res) => {
            if (res.data) {
              _quetureList.push(res.data);
            }
          });

          setQuetureList(_quetureList);
          setLoading(false);
        })
      );
    }

    return () => {
    };
  }, [quetureIds]);

  // console.log(quetureIds);
  function addStringList(str, searchStr) {
    let strList = [];
    let s = str;
    const _searchStr = searchStr;
    while (s.length !== 0) {
      const idx = s.indexOf(_searchStr);
      if (searchTitle === "") {
        strList.push(s)
        s = s.substr(s.length);
      }
      if (idx != -1 && idx != 0) {
        strList.push(s.substr(0, idx));
        s = s.substr(idx);
        strList.push(s.substr(0, _searchStr.length));
        s = s.substr(_searchStr.length);
      } else if (idx === 0) {
        strList.push(s.substr(0, _searchStr.length));
        s = s.substr(_searchStr.length);
      } else {
        strList.push(s)
        s = s.substr(s.length);
      }
    }


    return strList;
  }


  return (
    <Skeleton loading={isLoading} active>
      {quetureList &&
      quetureList.map((queture, index) => (
        <Row key={queture.quetureId}
             className={classNames("ht-queture-list")}
             style={{ padding: '0px', margin: '0px' }}
        >
          {/* 볼 수 없을시 is-cover */}

          <div className="textwrap"
               style={{
                 flex: 1, width: '1120px', marginLeft: '40px', paddingTop: '0px', borderTop: (index !==0 ? '2px solid #D7E0E7' : '0px')
               }}>
            {/*<strong>{`${numberToText(index + 1)}번째 깨처`}</strong>*/}

            <Col span={9} style={{
              minWidth: '408px',
              width: '408px',
            }}>
              <div
                className={"title"}
                style={{
                  minWidth: '400px',
                  width: '400px',
                  height: '80px',
                  color: "rgba(51,58,83,1)",
                  fontSize: '21px',
                  justifyContent: "center",
                  letterSpacing: '-3%',
                  display: 'table-cell',
                  verticalAlign: 'middle',
                  lineHeight: '28.8px',
                  fontWeight: '500',
                  paddingLeft: '10px',
                  fontFamily: 'Noto Sans KR',
                }}
              >{queture.title && addStringList(queture.title,searchTitle).map((e,idx)=>
                <span style={{backgroundColor: e === searchTitle ? '#ffff00' : 'rgba(0,0,0,0)'}}>
                  {e}
                </span>)}
              </div>
            </Col>
            <Col span={7}>
              <p style={{
                lineHeight: '21.6px',
                width: '380px',
                height: "80px",
                fontSize: '18px',
                display: 'table-cell', verticalAlign: 'middle',
                color: '#7F92A3',
              }}>
                {queture.conceptTags.join(",") && addStringList(queture.conceptTags.join(","),searchTitle).map((e,idx)=>
                  <span style={{backgroundColor: e === searchTitle ? '#ffff00' : 'rgba(0,0,0,0)'}}>
                  {e}
                </span>)}
                {/*{queture.conceptTags.join(",")}*/}
              </p>
            </Col>
            <Col
              className={classNames("btnwrap is-chk", {})}
              style={{ left: '90px',marginLeft : 'auto 0',display: 'table-cell',verticalAlign : 'middle',height:'80px', }}
            >
              <button
                className="review-layout-button"
                onClick={() => {
                  onShowQueturePopup(queture);
                }}>
                <p className={"review-layout-font"} style={{fontWeight: 500,}}>
                  깨처 복습하기
                </p>
              </button>

              {handleFindSolveQuiz(queture,lectureId) === true &&(<button
                className="review-layout-button"
                onClick={() => onEnterSolveQuiz(queture, lectureId)}
              >
                <p className={"review-layout-font"} style={{ fontWeight: 500, }}>
                  문제 다시풀기
                </p>
              </button>)}
            </Col>
          </div>
        </Row>
      ))}
    </Skeleton>
  );
};

export default ReviewQuetureList;
