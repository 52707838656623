import React, { useState, useEffect } from "react";
import ACheckbox from "../../../components/Teacher/selector/ACheckbox";
import Classmodebtn from "../../../components/Teacher/button/Classmodebtn";
import { Skeleton } from "antd";
import {
  orderBy,
  values,
  mapValues,
  remove,
  filter,
  map,
  sortedUniq,
} from "lodash";
import classNames from "classnames";

import "./_sendmsg.scss";
import axios from "axios";
import { getHost, getDefaultConfig } from "../../../utils/APIUtils";
import Toast from "components/Toast/Toast";
import PreviewMsgPopup from "../components/PreviewMsgPopup";

const msgCommon = `
        
홈트레이닝[오늘의 과제] 접속 링크 : www.quebon.ac  ※ 홈트레이닝은 반드시 PC 환경에서 크롬을 통해 진행해주세요.
학생 아이디 : [[학생 아이디]]
비밀번호 : 학생 생년월일 8자리
`;
const msgContentType = {
  basic:
    `안녕하세요 {{n}}번째 수업이 모두 끝났습니다.
오늘 <<이름>>(이)가 배운 깨처는 (영역) 영역의
(깨처)
입니다. 

수업 직후 깨처 다시보기와 함께 [오늘의 과제] 및 워크북을 진행해주세요. 
워크북은 매 수업이 끝날 때마다 유인물로 지급됩니다. 
부모님께서 직접 채점해주시고 틀린 문제는 다시 풀도록 지도해주세요. 
이해하기 어렵거나 설명이 필요한 문제는 별도로 체크해주시기 바랍니다. ` + msgCommon,
  absence:
    `안녕하세요 <<이름>>학생이 오늘 결석을 했네요.
[오늘의 과제]와 워크북을 진행해주세요.` + msgCommon,
  unfinish:
    `안녕하세요. 오늘은 <<이름>> 학생이 함께 수업 마무리를 하지 못했습니다.
[오늘의 과제]와 워크북을 진행해주세요.` + msgCommon,
};

const Sendmsg = (props) => {
  const { lectureData, classroomId } = props;
  const { seq, attendances, students, sections, lectureId } = lectureData;
  const [studentList, setStudentList] = useState([]);
  const [checkedStudentList, setCheckStudentList] = useState([]);
  const [sendMsgList, setSendMsgList] = useState([]);
  const [msgType, setMsgType] = useState("basic");
  const [correctCount, setCorrectCount] = useState(null);
  const [msgContent, setMsgContent] = useState(msgContentType["basic"]);
  const [isLoading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState(null);

  const [quetureData, setQuetureData] = useState(null);

  useEffect(() => {
    const quetureSections = filter(sections, function (o) {
      return o.type === "QUETURE";
    });

    const quizSections = filter(sections, function (o) {
      return o.type === "QUIZ";
    });

    console.log("quizList", quizSections);
    let _studentInfoList = {};

    students.map((student) => {
      student["count"] = 0;
      if(student.name)
      _studentInfoList[student.studentId] = student;
    });

    axios.all(quetureSections.map((q) => axios.get(
      `${getHost()}/content/v2/quetures/${q.quetureId}`,
      {
        ...getDefaultConfig(),
      }
    ))).then(axios.spread((...responses) => {
      console.log("queture list data: ", responses);
      const quetureData = [];
      const regionMap = {};

      responses.map((r, i) => {
        const region = r.data.region && !regionMap[r.data.region] ? r.data.region : null;
        if (region) {
          regionMap[region] = true
        }
        quetureData.push({
          title: `${i + 1}. ${r.data.title}`,
          region: region
        })
      })

      setQuetureData(quetureData);
    }))

    // add quiz correct count
    quizSections &&
      quizSections.map((quizInfo) => {
        const quizList = quizInfo.quiz;
        quizList.map((quiz) => {
          if (
            Object.keys(quiz.solvedMap) &&
            Object.keys(quiz.solvedMap).length > 0
          ) {
            Object.keys(quiz.solvedMap).map((studentId) => {
              if (_studentInfoList[studentId]) {
                if (quiz.solvedMap[studentId].correct === true) {
                  _studentInfoList[studentId]["count"]++;
                }
              }
            });
          }
        });
      });

    // Game
    const gameSections = filter(sections, function (o) {
      return o.type === "GAME";
    });
    const gameRanking =
      gameSections && gameSections[0] && gameSections[0].ranking;
    if (gameRanking) {
      gameRanking.map((r, index) => {
        if(_studentInfoList[r.student.studentId]) {
          _studentInfoList[r.student.studentId].score = r.score;
          _studentInfoList[r.student.studentId].time = r.time;
          _studentInfoList[r.student.studentId].gameRanking = index + 1;
        }
      });
    }

    // add attendance info
    const attendanceList = values(
      mapValues(attendances, function (value, key) {
        value.studentId = key;
        return value;
      })
    );

    students.map((student) => {
      const _studentId = student.studentId;

      const attendanceInfo = filter(attendanceList, function (o) {
        return o.studentId === _studentId;
      });

      console.log("attendanceInfo", attendanceInfo);

      student["attendanceTime"] = attendanceInfo[0].timestamp;
      student["attendanceStatus"] = attendanceInfo[0].status;
    });

    const _studentList = orderBy(
      values(
        mapValues(_studentInfoList, function (value, key) {
          value.studentId = key;
          return value;
        })
      ),
      ["attendanceTime"],
      ["asc"]
    );

    console.log("_studentInfoList: ", _studentList);

    const correctCount = map(_studentInfoList, "count").sort().reverse();
    setCorrectCount(sortedUniq(correctCount));

    setStudentList(_studentList);

    return () => { };
  }, [lectureData]);

  function handleCheckStudent(e) {
    console.log(e.target.checked);
    console.log(e.target.value);

    let _checkedStudentList = checkedStudentList;
    let _sendList = sendMsgList;

    if (e.target.checked) {
      _checkedStudentList = [e.target.value, ..._checkedStudentList];
      const _student = filter(students, function (o) {
        return o.studentId === e.target.value;
      });
      _sendList = [..._student, ..._sendList];
    } else {
      _checkedStudentList = remove(_checkedStudentList, function (n) {
        return n !== e.target.value;
      });
      _sendList = remove(_sendList, function (o) {
        return o.studentId !== e.target.value;
      });
    }
    setCheckStudentList(_checkedStudentList);
    setSendMsgList(_sendList);
  }

  function handleChangeMsgType(e) {
    setMsgType(e.target.value);
    setMsgContent(msgContentType[e.target.value]);
  }

  function handleMsgContent(e) {
    setMsgContent(e.target.value);
  }

  function handlePreviewData() {
    if (sendMsgList.length === 0) {
      Toast.warning("학생을 선택해주세요");
      return;
    }

    const _previewData = [...sendMsgList];

    _previewData.map((student) => {
      // _previewData.push({
      //   student: student,
      //   template: parseMsgContent(student, null, seq)
      // });
      student["template"] = parseMsgContent(student, null, seq);
    });

    setPreviewData(_previewData);
    console.log(sendMsgList);
    return;
  }

  function handleSendMsg() {
    if (sendMsgList.length === 0) {
      Toast.warning("학생을 선택해주세요");
      return;
    }

    const _sendMsgList = [];
    setLoading(true);
    previewData.map((student) => {
      _sendMsgList.push(
        axios.post(
          `${getHost()}/academy/v2/class/${classroomId}/lectures/${lectureId}/students/${student.studentId
          }/messages`,
          {
            template: student.template,
          },
          {
            ...getDefaultConfig(),
          }
        )
      );
    });

    axios.all(_sendMsgList).then(
      axios.spread((...responses) => {
        console.log("queture responses: ", responses);
        Toast.success("발송 완료");
        setLoading(false);
        setPreviewData(null);
      })
    );
  }

  function parseMsgContent(student, quetureNames, seq) {
    if (!student) {
      return null;
    }

    console.log('lectureData', lectureData);

    let _content = msgContent;

    const nameRegex = /\<\<(.*?)\>\>/gim;
    const nameText = student.name;
    const accountRegex = /\[\[(.*?)\]\]/gim;
    const quetureRegex = /\(깨처\)/gim;
    const quetureText = (quetureData && quetureData.length > 0) ? quetureData.map((q) => q.title).reduce((a, b) => a + "\n" + b) : "-";
    const regionRegex = /\(영역\)( 영역의)?/gim;
    const regions = quetureData.filter(q => q.region).map(q => q.region);
    const regionText = (regions && regions.length > 0) ? (regions.reduce((a, b) => a + ", " + b) + "$1") : "";
    const seqRegex = /\{\{(.*?)\}\}/gim;

    _content = _content
      .replace(nameRegex, nameText)
      .replace(accountRegex, student.account)
      .replace(quetureRegex, quetureText)
      .replace(regionRegex, regionText);

    if (seq) {
      _content = _content.replace(seqRegex, seq);
    }

    //     if (seq !== 0) {
    //       _content =
    //         _content +
    //         `

    // 홈트레이닝[오늘의 과제] 접속 링크 : www.quebon.ac  ※ 홈트레이닝은 반드시 PC 환경에서 크롬을 통해 진행해주세요.
    // 학생 아이디 : ${student.account}
    // 비밀번호 : 학생 생년월일 8자리

    // ※ 워크북은 매 수업이 끝날 때마다 유인물로 지급됩니다.
    // ※ 해설지(4회차 수업 분량)를 보시고, 매주 수업 날 전까지 부모님께서 직접 채점해주세요.
    // `;
    //     }

    return _content;
  }
  // ※ 홈트레이닝 접속 후 우측 상단의 설정 메뉴를 통해 자녀의 사진을 등록해주세요.
  console.log("checked Student List: ", checkedStudentList);
  console.log("send msg Student List: ", sendMsgList);
  console.log("msgType: ", msgType);
  console.log("studentList: ", studentList);
  console.log("correcount: ", correctCount);

  return (
    <Skeleton loading={!lectureData} active>
      <div className="msg-layout">
        <div className="big-title u-color-blue">수업종료 문자발송</div>
        <div className="msg-content">
          <section className="msg-target">
            <div className="section-title">문자 발송 대상 선택</div>
            <div className="msg-target-wrap">
              <ul className="title">
                <li>이름</li>
                <li>출석</li>
                <li>문제풀기</li>
                <li>게임</li>
              </ul>
              <div className="content">
                <table className="info">
                  <tbody>
                    {studentList.map((student, index) => (
                      <tr
                        key={index}
                        className={classNames({
                          "not-attend":
                            !student.attendanceTime || student.status === "no",
                        })}
                      >
                        <td>
                          <ACheckbox
                            id={`student-chk-${student.studentId}`}
                            name="studentChk"
                            onChange={(e) => handleCheckStudent(e)}
                            value={student.studentId}
                            checked={checkedStudentList.includes(
                              student.studentId
                            )}
                          />
                        </td>
                        <td className="name">
                          {student.name}{" "}
                          <span>
                            {student.birthday && student.birthday.age}세
                          </span>
                        </td>
                        {!student.attendanceTime || student.status === "no" ? (
                          <td className="attend" colSpan="3">
                            결석
                          </td>
                        ) : (
                            <>
                              <td className="attend">{index + 1}등</td>
                              <td className="ps">
                                {student.count > 0
                                  ? correctCount.findIndex(function (o) {
                                    return o === student.count;
                                  }) +
                                  1 +
                                  "등"
                                  : ""}
                              </td>
                              <td className="game">
                                {student.gameRanking
                                  ? student.gameRanking + "등"
                                  : ""}
                              </td>
                            </>
                          )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <section className="msg-write">
            <div className="section-title">문자 내용</div>
            <div className="msg-write-wrap">
              <div className="chkbox-wrap">
                <ACheckbox
                  text="기본"
                  id={`basic`}
                  name="msgType"
                  value={"basic"}
                  checked={msgType === "basic"}
                  onChange={(e) => handleChangeMsgType(e)}
                />
                <ACheckbox
                  text="결석"
                  id={`absence`}
                  name="msgType"
                  value={"absence"}
                  checked={msgType === "absence"}
                  onChange={(e) => handleChangeMsgType(e)}
                />
                <ACheckbox
                  text="미완료"
                  id={`unfinish`}
                  name="msgType"
                  value={"unfinish"}
                  checked={msgType === "unfinish"}
                  onChange={(e) => handleChangeMsgType(e)}
                />
              </div>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                value={msgContent}
                onChange={(e) => handleMsgContent(e)}
              />
              <div className="section-title">수신번호</div>
              <div className="recieve-num">
                <ul>
                  {sendMsgList.map((student) => (
                    <li key={student.studentId}>
                      <span className="name">{student.name}</span>
                      <span className="num">{student.parentMobile}</span>
                    </li>
                  ))}
                </ul>
              </div>
              {quetureData &&
                <Classmodebtn
                  text={isLoading ? "발송중..." : "문자 발송하기"}
                  onClick={() => {
                    handlePreviewData();
                  }}
                />}
            </div>
          </section>
        </div>
      </div>
      {previewData && (
        <PreviewMsgPopup
          title={`문자 내용 확인`}
          visible={previewData}
          maskClosable={false}
          // width={800}
          onOk={() => {
            if (isLoading) {
              return;
            }
            handleSendMsg();
          }}
          onCancel={() => {
            setPreviewData(null);
          }}
          okText="발송하기"
          cancelText="취소"
          data={previewData}
        />
      )}
    </Skeleton>
  );
};

export default Sendmsg;
