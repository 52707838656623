import { Tag } from "antd";
import React from "react";
import { formateSectionState } from "utils/utils";

const SectionStateTag = ({ state }) => {
  if (!state) return null;
  return (
    <Tag
      color={state === "forFree" ? "green" : "geekblue"}
      style={{ margin: "0 4px" }}
    >
      {formateSectionState(state)}
    </Tag>
  );
};

export default SectionStateTag;
